import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import TotalCountCard from "../../../container/TotalCountCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getAssetsList } from "../../../ReduxToolkit/Reducers/AssetManagementSlice";
import {
  ActiveAssets,
  NeedAttentions,
  AddNew,
  LessThanThreeMonths,
  NeedAttention,
  Assets,
  NoDataText,
  NotYetAdded,
  AssetsManagementListingDescription,
  AssetsManagement,
  NeedsReplacementOrNeedsRepair,
  NeedReplacement,
  Retired,
} from "../../../utils/Constant";

import "./style.scss";

const AssestManagement = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [parsedAssets, setParsedAssets] = useState([]);
  const { assetsList } = useAppSelector(state => state.assets);
  const [totalCountData, setTotalCountData] = useState([
    { count: 0, title: ActiveAssets, icon: "active-asset", id: "active" },
    {
      count: 0,
      title: NeedAttentions,
      sideTitle: LessThanThreeMonths,
      icon: "attention-asset",
      id: "need_attention",
    },
    {
      count: 0,
      title: NeedsReplacementOrNeedsRepair,
      icon: "replacement-asset",
      id: "replaced",
    },
    { count: 0, title: Retired, icon: "retired-asset", id: "retired" },
  ]);
  const [activeStatus, setActiveStatus] = useState("active");

  useEffect(() => {
    dispatch(
      getAssetsList({
        status: activeStatus,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (assetsList) {
      setTotalCountData([
        {
          count: assetsList?.resource_meta?.total_active_assets || 0,
          title: ActiveAssets,
          icon: "active-asset",
          id: "active",
        },
        {
          count: assetsList?.resource_meta?.need_attention || 0,
          title: NeedAttentions,
          sideTitle: LessThanThreeMonths,
          icon: "attention-asset",
          id: "need_attention",
        },
        {
          count: assetsList?.resource_meta?.need_replaced || 0,
          title: NeedsReplacementOrNeedsRepair,
          icon: "replacement-asset",
          id: "replaced",
        },
        {
          count: assetsList?.resource_meta?.retired || 0,
          title: Retired,
          icon: "retired-asset",
          id: "retired",
        },
      ]);
      const addedAssets = [];
      assetsList?.resource_type?.forEach(item => {
        addedAssets.push({
          id: item?.resource_type_id,
          assetName: item?.name,
          depedentBadges: [
            {
              id: 1,
              badge: ` ${Assets}: ${item?.total_assets || 0}`,
            },
            {
              id: 1,
              badge: `${ActiveAssets}: ${item?.total_active_assets || 0}`,
            },
            {
              id: 2,
              badge: `${NeedAttention}: ${item?.need_attention || 0}`,
            },
            {
              id: 3,
              badge: `${NeedReplacement}: ${item?.need_replaced || 0}`,
            },
          ],
        });
      });
      setParsedAssets(addedAssets);
    }
  }, [assetsList]);

  const filteredAssets = useMemo(() => {
    if (searchQuery) {
      return parsedAssets?.filter(item =>
        item.assetName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return parsedAssets;
  }, [searchQuery, parsedAssets]);

  const handleCardClick = (id: number, name: string) => {
    return navigate(
      `${process.env.PUBLIC_URL}/master-data/asset-management/assets-list?asset_id=${id}&name=${name}`
    );
  };

  const onIconClick = async id => {
    let parsedStatus = "";
    if (activeStatus === "" || activeStatus !== id) {
      parsedStatus = id;
      setActiveStatus(id);
    } else {
      setActiveStatus("");
    }
    await dispatch(
      getAssetsList({
        status: parsedStatus,
      })
    );
  };

  const renderContent = () => {
    if (parsedAssets?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          description={AssetsManagementListingDescription}
          buttonText={AddNew}
          onclick={() =>
            navigate("/master-data/asset-management/add-asset-management")
          }
        />
      );
    }
    if (filteredAssets?.length === 0) {
      return <NoData svg={"empty-folder-icon"} title={NoDataText} />;
    }
    return filteredAssets?.map(item => {
      return (
        <Card
          className="p-2 pointer"
          key={item.id}
          onClick={() => handleCardClick(item?.id, item?.assetName)}
        >
          <CardBody>
            <div className="d-flex flex-row align-items-center">
              <H3 className="fw-bold">{item?.assetName}</H3>
              <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
                {item?.depedentBadges?.map(badgeItem => (
                  <Badge
                    key={badgeItem?.id}
                    className="text-gray custom-asset-badge asset-list__badge"
                  >
                    {badgeItem?.badge}
                  </Badge>
                ))}
              </div>
            </div>
          </CardBody>
        </Card>
      );
    });
  };

  return (
    <div className="page-body asset-list">
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3>{AssetsManagement}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              {parsedAssets?.length > 0 && (
                <Link to={"/master-data/asset-management/add-asset-management"}>
                  <Btn
                    icon={
                      <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                    }
                    color="primary"
                  >
                    {AddNew}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="mb-3">
        <TotalCountCard
          data={totalCountData}
          onIconClick={onIconClick}
          activeStatus={activeStatus}
          showColor={true}
        />
      </div>
      {renderContent()}
    </div>
  );
};

export default AssestManagement;
