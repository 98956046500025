import PropTypes from "prop-types";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { Col, Form, FormGroup, Row } from "reactstrap";

import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { updateMaintainanceData } from "../../ReduxToolkit/Reducers/AssetManagementSlice";
import { uploadFileApi } from "../../ReduxToolkit/Reducers/UserSlice";
import {
  CalibrationDateRequired,
  Enter,
  LastCalibrationDate,
  LastMaintenanceDate,
  MaintenanceDateRequired,
  ProofOfCalibration,
  ProofOfMaitainance,
  Select,
  Submit,
  UpdateCalibration,
  UpdateCalibrationAndMaintenance,
  UpdateMaintenance,
} from "../../utils/Constant";
import { formatDate, showToast } from "../../utils/helper/helper";
import LabelTooltip from "../LabelTooltip";
import CommonModal from "../Modal";
import MultiFileUpload from "../MultiFileUpload/MultiFileUpload";

interface UpdateAssetModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSubmit?: () => void;
  getUpdatedData?: () => void;
  selectedData?: any;
}

const UpdateAssetModal: React.FC<UpdateAssetModalProps> = ({
  isOpen,
  toggle,
  selectedData,
  getUpdatedData,
}) => {
  const dispatch = useAppDispatch();
  const [calibrationDocument, setCalibrationDocument] = useState([]);
  const [maintainenceDocument, setMaintainenceDocument] = useState([]);
  const [caliberationDate, setCaliberationDate] = useState<Date | null>(
    selectedData?.calibration_valid_till
      ? new Date(selectedData.calibration_valid_till)
      : null
  );
  const [maintainanceDate, setMaintainanceDate] = useState<Date | null>(
    selectedData?.maintenance_valid_till
      ? new Date(selectedData.maintenance_valid_till)
      : null
  );

  const isPastOrInvalidDate = (date: string | null | undefined) => {
    return new Date(date) <= new Date() && date != null;
  };

  const getUpdateAssetModalBody = () => (
    <Form>
      <Row>
        {isPastOrInvalidDate(selectedData?.calibration_valid_till) && (
          <Col>
            <FormGroup>
              <LabelTooltip
                label={ProofOfCalibration}
                tooltipText={`${ProofOfCalibration}`}
                placement={"top"}
                important={false}
              />
              <MultiFileUpload
                accept=".pdf,.docx"
                multiple={true}
                onFileChange={(file, status) => {
                  if (status === "removed") {
                    setCalibrationDocument(prevDocuments =>
                      prevDocuments.filter(item => item.name !== file.name)
                    );
                  } else if (status === "done" && file) {
                    setCalibrationDocument(prevDocuments => [
                      ...prevDocuments,
                      file,
                    ]);
                  }
                }}
              />
            </FormGroup>
          </Col>
        )}

        {isPastOrInvalidDate(selectedData?.maintenance_valid_till) && (
          <Col>
            <FormGroup>
              <LabelTooltip
                label={ProofOfMaitainance}
                tooltipText={`${ProofOfMaitainance}`}
                placement={"top"}
                important={false}
              />
              <MultiFileUpload
                accept=".pdf,.docx"
                multiple={true}
                onFileChange={(file, status) => {
                  if (status === "removed") {
                    setMaintainenceDocument(prevDocuments =>
                      prevDocuments.filter(item => item.name !== file.name)
                    );
                  } else if (status === "done" && file) {
                    setMaintainenceDocument(prevDocuments => [
                      ...prevDocuments,
                      file,
                    ]);
                  }
                }}
              />
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row>
        {isPastOrInvalidDate(selectedData?.calibration_valid_till) && (
          <Col>
            <FormGroup>
              <LabelTooltip
                label={LastCalibrationDate}
                tooltipText={`${Enter} ${LastCalibrationDate}`}
                placement={"top"}
                important
              />
              <DatePicker
                selected={caliberationDate}
                onChange={(date: Date | null) => setCaliberationDate(date)}
                maxDate={new Date()}
                placeholderText={`${Select} ${LastCalibrationDate}`}
                className="form-control"
                dateFormat="dd-MM-yyyy"
                disabledKeyboardNavigation={true}
                onKeyDown={e => e.preventDefault()}
              />
            </FormGroup>
          </Col>
        )}

        {isPastOrInvalidDate(selectedData?.maintenance_valid_till) && (
          <Col>
            <FormGroup>
              <LabelTooltip
                label={LastMaintenanceDate}
                tooltipText={`${Enter} ${LastMaintenanceDate}`}
                placement={"top"}
                important
              />
              <DatePicker
                selected={maintainanceDate}
                onChange={(date: Date | null) => setMaintainanceDate(date)}
                maxDate={new Date()}
                placeholderText={`${Select} ${LastMaintenanceDate}`}
                className="form-control"
                dateFormat="dd-MM-yyyy"
                disabledKeyboardNavigation={true}
                onKeyDown={e => e.preventDefault()}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
    </Form>
  );

  const uploadFile = async (documents: File[]) => {
    const postData = new FormData();
    documents?.forEach(file => postData.append("files", file));
    const response = await dispatch(uploadFileApi({ content: postData }));
    return response?.payload?.data?.file_urls || [];
  };

  const handleSubmit = async () => {
    let uploadedCalibrationFiles = [];
    if (calibrationDocument.length > 0) {
      uploadedCalibrationFiles = await uploadFile(calibrationDocument);
    }

    let uploadedMaintainenceFiles = [];
    if (maintainenceDocument.length > 0) {
      uploadedMaintainenceFiles = await uploadFile(maintainenceDocument);
    }

    if (
      isPastOrInvalidDate(selectedData?.calibration_valid_till) &&
      !caliberationDate
    ) {
      showToast(CalibrationDateRequired, "error");
      return;
    }

    if (
      isPastOrInvalidDate(selectedData?.maintenance_valid_till) &&
      !maintainanceDate
    ) {
      showToast(MaintenanceDateRequired, "error");
      return;
    }

    const payload = {
      resource_id: selectedData?.resource_id || null,
      calibration_document: uploadedCalibrationFiles,
      maintenance_document: uploadedMaintainenceFiles,
      other_document: [],
      last_calibration_date: caliberationDate
        ? formatDate(caliberationDate)
        : null,
      last_maintenance_date: maintainanceDate
        ? formatDate(maintainanceDate)
        : null,
    };

    await dispatch(updateMaintainanceData({ payload }));
    getUpdatedData();
    setCalibrationDocument([]);
    setMaintainenceDocument([]);
    setCaliberationDate(null);
    setMaintainanceDate(null);
    toggle();
  };

  let sizeTitle;
  if (
    isPastOrInvalidDate(selectedData?.calibration_valid_till) &&
    isPastOrInvalidDate(selectedData?.maintenance_valid_till)
  ) {
    sizeTitle = UpdateCalibrationAndMaintenance;
  } else if (isPastOrInvalidDate(selectedData?.calibration_valid_till)) {
    sizeTitle = UpdateCalibration;
  } else if (isPastOrInvalidDate(selectedData?.maintenance_valid_till)) {
    sizeTitle = UpdateMaintenance;
  } else {
    sizeTitle = UpdateCalibrationAndMaintenance;
  }

  return (
    <CommonModal
      backdrop="static"
      size={
        isPastOrInvalidDate(selectedData?.calibration_valid_till) &&
        isPastOrInvalidDate(selectedData?.maintenance_valid_till)
          ? "xl"
          : "lg"
      }
      isOpen={isOpen}
      toggle={toggle}
      sizeTitle={sizeTitle}
      showFooter={true}
      primaryBtnText={Submit}
      onPrimaryBtnClick={handleSubmit}
    >
      {getUpdateAssetModalBody()}
    </CommonModal>
  );
};

UpdateAssetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  getUpdatedData: PropTypes.func,
  selectedData: PropTypes.object,
};

export default UpdateAssetModal;
