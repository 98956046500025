import PropTypes from "prop-types";
import { useState } from "react";
import { Form, FormGroup, Input } from "reactstrap";

import {
  AddReason,
  AssetReasonRequired,
  Enter,
  Reason,
  ReplacementReason,
  Select,
  Submit,
} from "../../utils/Constant";
import { ReasonOptions, showToast } from "../../utils/helper/helper";
import LabelTooltip from "../LabelTooltip";
import CommonModal from "../Modal";

interface ReasonAssetModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSubmit: (reason: any) => void;
  setFormStateData: any;
}

const ReasonAssetModal: React.FC<ReasonAssetModalProps> = ({
  isOpen,
  toggle,
  onSubmit,
  setFormStateData,
}) => {
  const [formState, setFormState] = useState({
    reason: null,
    otherReason: "",
  });

  const handleInputChange = (fieldName, value) => {
    setFormStateData(prev => ({ ...prev, [fieldName]: value }));
    setFormState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const { reason, otherReason } = formState;
    const selectedReason =
      reason?.value === "other_specify" ? otherReason : reason;
    if (!selectedReason || selectedReason === "") {
      showToast(AssetReasonRequired, "error");
      return;
    }
    onSubmit(selectedReason);
    toggle();
  };

  const onToggle = () => {
    setFormState({
      reason: null,
      otherReason: "",
    });
    toggle();
  };

  const getReasonAssetModalBody = () => (
    <Form>
      <FormGroup>
        <LabelTooltip
          label={Reason}
          tooltipText={`${Select} ${Reason}`}
          placement={"top"}
          important
        />
        <Input
          type="select"
          id="reason-dropdown"
          value={formState?.reason || ""}
          onChange={e => {
            const selectedValue = e.target.value;
            const selectedOption = ReasonOptions.find(
              option => option.value === selectedValue
            );
            handleInputChange("reason", selectedOption?.value);
          }}
        >
          <option value="" disabled>
            {`${Select} ${Reason}`}
          </option>
          {ReasonOptions?.map(reason => (
            <option key={reason.value} value={reason.value}>
              {reason.label}
            </option>
          ))}
        </Input>
      </FormGroup>
      {formState.reason === "other_specify" && (
        <FormGroup>
          <LabelTooltip
            label={AddReason}
            tooltipText={`${Enter} ${Reason}`}
            placement={"top"}
            important
          />
          <Input
            type="textarea"
            name="invoiceNumber"
            placeholder={`${Enter} ${Reason}`}
            value={formState.otherReason}
            onChange={e => {
              if (
                (e.target.value && e.target.value?.length <= 255) ||
                e.target.value === ""
              )
                handleInputChange("otherReason", e.target.value);
            }}
          />
        </FormGroup>
      )}
    </Form>
  );
  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={onToggle}
      sizeTitle={ReplacementReason}
      showFooter={true}
      primaryBtnText={Submit}
      onPrimaryBtnClick={handleSubmit}
    >
      {getReasonAssetModalBody()}
    </CommonModal>
  );
};

ReasonAssetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setFormStateData: PropTypes.func,
};

export default ReasonAssetModal;
