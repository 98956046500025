import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  CREATE_ASSET_DATA,
  CREATE_ASSET_DATA_URL,
  GET,
  GET_ASSET_DETAILS_BY_ID,
  GET_ASSET_LIST,
  GET_ASSET_LIST_BY_ID,
  GET_ASSET_LIST_BY_ID_URL,
  GET_ASSET_DETAILS_BY_ID_URL,
  GET_ASSET_LIST_URL,
  GET_ASSET_TYPES,
  GET_ASSET_TYPES_URL,
  POST,
  PUT,
  UPDATE_ASSET_DATA,
  GET_ASSET_HISTORY,
  GET_ASSET_HISTORY_URL,
  GET_DOCUMENTS,
  GET_DOCUMENTS_URL,
  UPDATE_MAINTAINANCE_DATA,
  UPDATE_MAINTAINANCE_DATA_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  Asset,
  AssetHistoryDetails,
  ResourceData,
  ResourceListData,
  ResourceTypeMap,
} from "../../Api/entities/AssetManagementEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface AssetManagement {
  loading: boolean;
  error: string | null;
  assetsList: ResourceData;
  assetTypes: ResourceTypeMap;
  assetTypeList: ResourceListData;
  assetDetails: Asset | any;
  assetHistory: AssetHistoryDetails | any;
  documents: any;
}

const initialState: AssetManagement = {
  loading: false,
  error: null,
  assetsList: {
    resource_meta: {
      total_assets: 0,
      total_active_assets: 0,
      need_attention: 0,
      need_replaced: 0,
      retired: 0,
    },
    resource_type: [],
  },
  assetTypes: { resource_type: [] },
  assetTypeList: { resource: [] },
  assetDetails: {},
  assetHistory: {},
  documents: [],
};

export const getAssetsList = createAsyncThunk(
  GET_ASSET_LIST,
  async ({ status }: { status?: string }) => {
    const response = await apiRequest(GET, GET_ASSET_LIST_URL(status));
    return response.data;
  }
);

export const getAssetsHistory = createAsyncThunk(
  GET_ASSET_HISTORY,
  async ({ id, instructor }: { id: string; instructor?: string }) => {
    const response = await apiRequest(
      GET,
      GET_ASSET_HISTORY_URL(id, instructor)
    );
    return response.data;
  }
);

export const getAssetsListById = createAsyncThunk(
  GET_ASSET_LIST_BY_ID,
  async ({
    id,
    status,
    location,
  }: {
    id: string;
    status?: string;
    location?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_ASSET_LIST_BY_ID_URL(id, status, location)
    );
    return response.data;
  }
);

export const getDocuments = createAsyncThunk(
  GET_DOCUMENTS,
  async ({ id }: { id: string }) => {
    const response = await apiRequest(GET, GET_DOCUMENTS_URL(id));
    return response.data;
  }
);

export const getAssetsDetailsById = createAsyncThunk(
  GET_ASSET_DETAILS_BY_ID,
  async ({ id }: { id: string }) => {
    const response = await apiRequest(GET, GET_ASSET_DETAILS_BY_ID_URL(id));
    return response.data;
  }
);

export const getAssetTypes = createAsyncThunk(GET_ASSET_TYPES, async () => {
  const response = await apiRequest(GET, GET_ASSET_TYPES_URL());
  return response.data;
});

export const createAssetData = createAsyncThunk(
  CREATE_ASSET_DATA,
  async (payload: any) => {
    return await apiRequest(POST, CREATE_ASSET_DATA_URL(), payload?.payload);
  }
);

export const updateMaintainanceData = createAsyncThunk(
  UPDATE_MAINTAINANCE_DATA,
  async (payload: any) => {
    return await apiRequest(
      PUT,
      UPDATE_MAINTAINANCE_DATA_URL(),
      payload?.payload
    );
  }
);

export const updateAssetData = createAsyncThunk(
  UPDATE_ASSET_DATA,
  async (payload: any) => {
    return await apiRequest(PUT, CREATE_ASSET_DATA_URL(), payload?.payload);
  }
);

const AssetManagementSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    clearAssetDetails: state => {
      state.assetDetails = initialState.assetDetails;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAssetsList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssetsList.fulfilled, (state, action) => {
        state.loading = false;
        state.assetsList = action.payload;
      })
      .addCase(getAssetsList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getDocuments.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload;
      })
      .addCase(getDocuments.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssetsHistory.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssetsHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.assetHistory = action.payload;
      })
      .addCase(getAssetsHistory.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssetsListById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssetsListById.fulfilled, (state, action) => {
        state.loading = false;
        state.assetTypeList = action.payload;
      })
      .addCase(getAssetsListById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssetsDetailsById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssetsDetailsById.fulfilled, (state, action) => {
        state.loading = false;
        state.assetDetails = action.payload;
      })
      .addCase(getAssetsDetailsById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssetTypes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssetTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.assetTypes = action.payload;
      })
      .addCase(getAssetTypes.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createAssetData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAssetData.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createAssetData.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateAssetData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAssetData.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateAssetData.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateMaintainanceData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateMaintainanceData.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateMaintainanceData.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const { clearAssetDetails } = AssetManagementSlice.actions;
export default AssetManagementSlice.reducer;
